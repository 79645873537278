import { isFocusable } from 'element-plus/lib/utils'

export default{
    statusFilter(val:string){
        switch (val){
            case "PAY_NO":
                return "未支付"
            case "PAY_WAIT" :
                return "等待支付"
            case "PAY_SUCCESS":
                return "支付成功"
            case "PAY_FAILED":
                return "支付失败"
            case "PAY_VERIFY" :
                return "等待银行审核"
            case "APPROVE_WAIT" :
                return "HR审核"
            case "APPROVE_FAILED" :
                return "审核失败"
            case "APPROVE_SUCCESS" :
                return "审核成功"
            case "APPROVE_SEC" :
                return "财务审核"
            case "APPROVE_NO" :
                return "未审核"
            case "APPROVE_PART" :
                return "部分已审核"
            case "TRUN_DOWN" :
                return "已驳回"
            case "PAYMENT_REISSUED" :
                return "已重新支付"
            case "PAY_PART_SUCCESS" :
                return "部分支付成功"
            case "TRANSFER_PAYROLL" :
                return "转薪酬"
            case "TRANSFER_PAYROLL_SUCCESS" :
                return "转薪酬-支付成功"
        }
    },
    filterType(type:string){
        switch(type){
            case  'COST_REIMBURSE':
                return "费用报销"
            case  'COST_APPLY':
                return "用款申请"
            case  'COST_INTERNAL':
                return "内部转账"
            case  'COST_RENT':
                return "房租水电"
            case  'COST_SALARY':
                return "薪酬发放"
        }
    },
    accountNO:[
            {value:"900000",label: "收款"},
            {value:"900001",label: "付款"},
            {value:"999999",label: "集团账户"},
            {value:"1000000",label: "CCJK-Shenzhen"},
            {value:"1000002",label: "CS"},
            {value:"1000003",label: "SAM"},
            {value:"1000004",label: "BA"},
            {value:"1000005",label: "MT"},
            {value:"1000006",label: "FS"},
            {value:"1000007",label: "WY"},
            {value:"1000008",label: "BJ"},
            {value:"1000009",label: "NC"},
            {value:"1000010",label: "WH"},
            {value:"1000011",label: "HZ"},
            {value:"1000012",label: "R&D"},
            {value:"1000013",label: "ZZ"},
            {value:"1000014",label: "资源部"},
            {value:"1000015",label: "工程部"},
            {value:"1000016",label: "人事行政部"},
            {value:"1000017",label: "财务部"},
            {value:"1000018",label: "房租物管水电"},
            {value:"1000019",label: "办公费用归集"},
            {value:"1000020",label: "发展基金"},
            {value:"1000021",label: "薪酬费用归集"},
            {value:"1000022",label: "总经办"},
            {value:"1000023",label: "固定资产归集"},
            {value:"1000024",label: "语言部"},
            {value:"1000025",label: "公共关系部"},
            {value:"1000026",label: "校企合作部"},
            {value:"1000027",label: "税费归集"},
            {value:"1000028",label: "中兴项目部"},
            {value:"1000029",label: "往来"},
            {value:"1000030",label: "采购应付"},
            {value:"1000032",label: "CCJK-JiBi"},
            {value:"1000033",label: "PK费用归集"},
            {value:"1000034",label: "销售成本调整"},
    ],
    accountNoFilter(accountNo:string|number){
        let accountNO=[
            {value:"900000",label: "收款"},
            {value:"900001",label: "付款"},
            {value:"999999",label: "集团账户"},
            {value:"1000000",label: "CCJK-Shenzhen"},
            {value:"1000002",label: "CS"},
            {value:"1000003",label: "SAM"},
            {value:"1000004",label: "BA"},
            {value:"1000005",label: "MT"},
            {value:"1000006",label: "FS"},
            {value:"1000007",label: "WY"},
            {value:"1000008",label: "BJ"},
            {value:"1000009",label: "NC"},
            {value:"1000010",label: "WH"},
            {value:"1000011",label: "HZ"},
            {value:"1000012",label: "R&D"},
            {value:"1000013",label: "ZZ"},
            {value:"1000014",label: "资源部"},
            {value:"1000015",label: "工程部"},
            {value:"1000016",label: "人事行政部"},
            {value:"1000017",label: "财务部"},
            {value:"1000018",label: "房租物管水电"},
            {value:"1000019",label: "办公费用归集"},
            {value:"1000020",label: "发展基金"},
            {value:"1000021",label: "薪酬费用归集"},
            {value:"1000022",label: "总经办"},
            {value:"1000023",label: "固定资产归集"},
            {value:"1000024",label: "语言部"},
            {value:"1000025",label: "公共关系部"},
            {value:"1000026",label: "校企合作部"},
            {value:"1000027",label: "税费归集"},
            {value:"1000028",label: "中兴项目部"},
            {value:"1000029",label: "往来"},
            {value:"1000030",label: "采购应付"},
            {value:"1000032",label: "CCJK-JiBi"},
            {value:"1000033",label: "PK费用归集"},
            {value:"1000034",label: "销售成本调整"},
        ]
        let res=accountNO.find((el:any)=>el.value==accountNo)
        return res?res.label:""
    },
    paymentCompany:[
        {name:'CCJK Technologies Co., Ltd.'},
        {name:'CCJK CO.,Ltd.'},
        {name:'CCJK Localization Pte Ltd.'},
        {name:'Mars Translation CO.,Ltd.'},
        {name:'Hunan Artlangs Translation Services Co.,Ltd.'},
        {name:'Shenzhen Sodoglobal CO.,Ltd.'},
        {name:'Wuhan Transee Translation Co., Ltd.'},
        {name:'WetransTech Media Solutions Co.,Ltd.'},
        {name:'MarsCrowd CO.,Ltd.'},
        {name:'LocalizationLine Solutions Co.,Ltd.'},
        {name:'Changsha BiAn language services co.,ltd'},
        {name:'Marshub Co.,Ltd.'},
        {name:'Changsha Beyond Cloud Translation Co.,Ltd.'},
        {name:'Wordspath Information Co.,Ltd.'},
        {name:'MarsHub(Hunan) Co., Ltd'},
        {name:'火星语盟（湖南）科技有限公司'},
        {name:'Mars Translation HK CO.,Ltd.'},
        {name:'深圳市昆仲科技有限公司（云账户）'},
        {name:'深圳市昆仲科技有限公司'},
        {name:'深圳市昆仲科技有限公司（工蚁宝）'},
        {name:'CCJK CO LIMITED'},
    ],
    invoiceType(type: string){
        // 'OR': '普票',
        // 'SH_OR': '共用普票',
        // 'SP': '专票',
        // 'SH_SP': '共用专票',
        // 'NO': '无票',
        // 'BILL': '单据',
        // 'CONFIRM_NO': '确认无票',
        switch(type){
            case 'OR':
                return "普票"
            case 'SH_OR':
                return "共用普票"
            case 'SP':
                return "专票"
            case 'SH_SP':
                return "共用专票"
            case 'NO':
                return "无票"
            case 'BILL':
                return "单据"
            case 'CONFIRM_NO':
                return "确认无票"
            case 'EI_SP':
                return "电子发票-专票"
            case 'EI_OR':
                return "电子发票-普票"
        }
    },
    currencyOption(type?: string) {
        return [
            { label: 'CNY', id: 'CNY' },
            { label: 'USD', id: 'USD' },
            { label: 'EUR', id: 'EUR' },
            { label: 'AUD', id: 'AUD' },
            { label: 'GBP', id: 'GBP' },
            { label: 'HKD', id: 'HKD' },
            { label: 'JPY', id: 'JPY' },
            { label: 'SGD', id: 'SGD' },
            { label: 'CAD', id: 'CAD' },
            { label: 'SGP', id: 'SGP' },
            { label: 'SEK', id: 'SEK' },
            { label: 'IDR', id: 'IDR' },
        ]
    }, 
}

export const businessCodeType = (bankName?: string) => {
    switch (bankName) {
        case '上海银行':
            return [
                {label: '批量转账', value: { valueStr: 'nis_batch_transfer', optionKey: '1' }},
            ]
        default:
            return [
                {label: '批量转账', value: { valueStr: 'nis_batch_transfer', optionKey: '1' }},
                {label: '费用报销', value: { valueStr: 'nis_smart_expense', optionKey: '2' }},
                {label: '宁波代发工资', value: { valueStr: 'nis_smart_wage', optionKey: '3' }},
                {label: '招行代发工资经办', value: { valueStr: 'nis_smart_wage', optionKey: '4' }},
                {label: '招行代发其他经办', value: { valueStr: 'nis_smart_other', optionKey: '5' }},
            ]
    }
}

// 服务方类型
export const serviceTypeOption = (type?: string): any => {
    if(type === 'option'){
        return [
            {label: '个人', id: 'personal'},
            {label: '自然人', id: 'natural_person'},
            {label: '同行公司', id: 'peer_companies'},
            {label: '云账户', id: 'cloud_account'},
        ]
    }
    return {
        'personal': '个人',
        'natural_person': '自然人',
        'peer_companies': '同行公司',
        'cloud_account': '云账户',
    }
}
// 开票类型
export const makeInvoiceTypeOption = (type?: string): any => {
    if(type === 'option'){
        return [
            { label: '普票', id: 'OR'},
            { label: '专票', id: 'SP'},
            { label: '电子发票-专票', id: 'EI_SP'},
            { label: '电子发票-普票', id: 'EI_OR'},
            { label: '无票', id: 'NO'},
            { label: '确认无票', id: 'CONFIRM_NO'},
            { label: '单据', id: 'BILL'},
            { label: '共用专票', id: 'SH_SP'},
            { label: '共用普票', id: 'SH_OR'},
        ]
    }
    return {
       'OR': '普票',
       'SP': '专票',
       'EI_SP': '电子发票-专票',
       'EI_OR': '电子发票-普票',
       'NO': '无票',
       'CONFIRM_NO': '确认无票',
       'BILL': '单据',
       'SH_SP': '共用专票',
       'SH_OR': '共用普票',
    }
}
// 币种
export const currencyOption = (type?: string) => {
  return [
    { label: 'CNY', id: 'CNY' },
    { label: 'USD', id: 'USD' },
    { label: 'EUR', id: 'EUR' },
    { label: 'AUD', id: 'AUD' },
    { label: 'GBP', id: 'GBP' },
    { label: 'HKD', id: 'HKD' },
    { label: 'JPY', id: 'JPY' },
    { label: 'SGD', id: 'SGD' },
    { label: 'CAD', id: 'CAD' },
    { label: 'SGP', id: 'SGP' },
    { label: 'SEK', id: 'SEK' },
    { label: 'IDR', id: 'IDR' },
  ]
}